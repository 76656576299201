<template>
  <div class="contents detailWrap">
    <div class="title flexB">
      <h2>회원정보</h2>
    </div>
    <div class="box">
      <div class="flex">
        <p>이름</p>
        <span>{{ withdrawAt ? "탈퇴회원" : username }}</span>
      </div>
      <div class="flex" v-if="withdrawAt == null">
        <p>권한</p>
        <label><input type="radio" value="false" v-model="isAdmin" />일반 </label>
        <label><input type="radio" value="true" v-model="isAdmin" />관리자 </label>
      </div>
      <div class="flex" v-if="withdrawAt == null">
        <p>구분</p>
        <label><input type="radio" value="USER" v-model="userType" />농장주 </label>
        <label><input type="radio" value="EXAMINER" v-model="userType" />심사원 </label>
      </div>
      <div class="flex" v-if="withdrawAt == null">
        <p>연락처</p>
        <span>{{ getMask(mobileNo) }}</span>
      </div>

      <div class="flex" v-if="withdrawAt == null">
        <p>마케팅수신동의</p>
        <span>{{ marketingAgreement == true ? "동의" : "미동의" }}</span>
      </div>
      <div class="flex">
        <p>가입일</p>
        <span>{{ moment(createdAt).format("YYYY.MM.DD HH:mm") }}</span>
      </div>
      <div class="flex" v-if="updatedAt">
        <p>최근 업데이트</p>
        <span>{{ moment(updatedAt).format("YYYY.MM.DD HH:mm") }}</span>
      </div>
      <div class="flex" v-if="withdrawAt">
        <p>탈퇴일</p>
        <span>{{ moment(withdrawAt).format("YYYY.MM.DD HH:mm") }}</span>
      </div>
      <div class="buttonWrap">
        <button v-if="!withdrawAt" class="basic" @click="submit">수정</button>
      </div>
    </div>
    <el-tabs v-model="activeName">
      <el-tab-pane label="농장 리스트" name="farms">
        <div class="box one">
          <table v-if="farmList.length > 0">
            <colgroup>
              <col style="width: 10%" />
              <col style="width: 20%" />
              <col style="width: 20%" />
              <col style="width: 30%" />
              <col style="width: 20%" />
            </colgroup>
            <tr>
              <th>번호</th>
              <th>농장명칭</th>
              <th>작물</th>
              <th style="text-align: left">주소</th>
              <th>등록일</th>
            </tr>
            <tr v-for="(data, i) in farmList" :key="i">
              <td>{{ data.id }}</td>
              <td>{{ data.farmName }}</td>
              <td>{{ data.crops }}</td>
              <td style="text-align: left">{{ data.address }}</td>
              <td>{{ moment(data.createdAt).format("YYYY.MM.DD HH:mm") }}</td>
            </tr>
          </table>
          <div class="nodata" v-else><p>데이터가 없습니다.</p></div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="영농일지 리스트" name="diarys">
        <div class="box one">
          <el-button class="line flr" @click="handleDownload" :loading="btnDisabled">
            <img v-if="btnDisabled == false" src="@/assets/images/icon_svg/excel.svg" alt="icon" class="normal" />

            다운로드</el-button
          >
          <table>
            <colgroup>
              <col style="width: 10%" />
              <col style="width: 20%" />
              <col style="width: 50%" />
              <col style="width: 20%" />
            </colgroup>
            <tr>
              <th>번호</th>
              <th>작물</th>
              <th style="text-align: left">카테고리</th>
              <th>등록일</th>
            </tr>
            <tr v-for="(data, i) in diaryList" :key="i" v-show="diaryList.length > 0">
              <td>{{ total - i - (currentPage - 1) * 10 }}</td>
              <!-- <td>{{ data.farmName }}</td> -->
              <td>{{ data.crop }}</td>
              <td style="text-align: left">
                <span v-for="(item, i) in data.category" :key="i">
                  #{{
                    item == "category1"
                      ? "농약"
                      : item == "category2"
                      ? "관수"
                      : item == "category3"
                      ? "해충기록"
                      : item == "category4"
                      ? "장비정비"
                      : item == "category5"
                      ? "수확"
                      : item == "category6"
                      ? "출고"
                      : item == "category7"
                      ? "기타"
                      : "기타"
                  }}&nbsp;&nbsp;
                </span>
              </td>
              <td>{{ moment(data.createdAt).format("YYYY.MM.DD HH:mm") }}</td>
            </tr>
          </table>
          <div class="pagination" v-if="diaryList.length > 0">
            <el-pagination layout="prev, pager, next" :total="total" :page-size="10" @current-change="handleCurrentChange"></el-pagination>
          </div>
          <div class="nodata" v-else><p>데이터가 없습니다.</p></div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import XLSX from "xlsx";
import moment from "moment";
import { fetchUserDetails, upadateUserDetails, fetchDiaryList, fetchDiaryDumpList } from "@/api/admin";
import { format } from "@/mixins/format";
export default {
  mixins: [format],
  name: "UserManage",

  data() {
    return {
      moment: moment,
      userId: "",
      isAdmin: "",
      username: "",
      userType: "",
      mobileNo: "",
      createdAt: "",
      updatedAt: "",
      withdrawAt: "",
      activeName: "farms",
      marketingAgreement: false,
      farmList: [],
      diaryList: [],
      isNodata: false,
      currentPage: 1,
      total: 0,
      btnDisabled: false,
    };
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", "1");
  },
  mounted() {
    this.userId = this.$route.query.id;
    this.getUserDetail();
    this.getDiaryList();
  },

  methods: {
    openAlert(msg) {
      this.$alert(msg, {
        confirmButtonText: "확인",
        center: true,
      });
    },
    getUserDetail() {
      fetchUserDetails(this.userId).then((res) => {
        if (res.data.status == 200) {
          this.isAdmin = res.data.userInfo.isAdmin;
          this.username = res.data.userInfo.username;
          this.userType = res.data.userInfo.userType;
          this.mobileNo = res.data.userInfo.mobileNo;
          this.createdAt = res.data.userInfo.createdAt;
          this.updatedAt = res.data.userInfo.updatedAt;
          this.withdrawAt = res.data.userInfo.withdrawAt;
          this.marketingAgreement = res.data.userInfo.marketingAgreement;
          this.farmList = res.data.userFarmList;
          this.isNodata = res.data.userFarmList.length == 0 ? true : false;
        }
      });
    },
    submit() {
      let data = {
        userId: this.userId,
        isAdmin: this.isAdmin,
        userType: this.userType,
      };
      upadateUserDetails(data).then((res) => {
        if (res.data.status == 200) {
          this.$notify({
            title: "Success",
            message: "회원 정보가 수정 되었습니다.",
            type: "success",
          });
          // this.$router.push("/admin/users");
        } else {
          return this.openAlert(res.data.message);
        }
      });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getDiaryList();
    },
    getDiaryList() {
      let params = {
        page: this.currentPage,
        farmer: this.userId,
      };
      fetchDiaryList(params).then((res) => {
        if (res.data.status == 200) {
          this.diaryList = res.data.data;
          this.total = res.data.total;
          this.isNodata = this.total == 0 ? true : false;
        } else {
          this.openAlert(res.data.message);
          return;
        }
      });
    },
    async handleDownload() {
      this.btnDisabled = true;
      //엑셀파일로 다운로드
      let params = {
        farmer: this.userId,
      };
      let res = await fetchDiaryDumpList(params);
      if (res.data.status == 200) {
        this.dumpList = res.data.data;
      } else {
        return this.openAlert(res.data.message);
      }

      let diaryData = this.dumpList.map((item, i) => {
        let data = {
          번호: i + 1,
          날짜: item.date,
          농장주: item.farmer ? item.farmer.username : "",
          작물: item.crop,
          // 초장: item.totalLT + "cm",
          // 잎개수: item.leafCount + "개",
          // 잎길이: item.leafLength + "cm",
          // 잎너비: item.leafWidth + "cm",
          등록일: moment(item.createdAt).format("YYYY.MM.DD HH:mm"),
        };
        return data;
      });

      var excelData = XLSX.utils.json_to_sheet(diaryData); // table id를 넣어주면된다
      var workBook = XLSX.utils.book_new(); // 새 시트 생성
      XLSX.utils.book_append_sheet(workBook, excelData, "영농일지"); // 시트 명명, 데이터 지정
      XLSX.writeFile(workBook, `영농일지_${this.username}.xlsx`); // 엑셀파일 만듬
      this.btnDisabled = false;
    },
  },
};
</script>
